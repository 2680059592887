export default {
  index: {
    selected: '已選中',
    salesPromotion: '促銷和費用',
    bookingTips: '請務必核實填寫信息，確認無誤後，再提交訂單',
    submitAndCollection: '提交並收款',
    noResourceTips: '您尚未選擇購買的資源，請選擇後再進行提交',
    validityTips2: '預訂當天（出發日期）開始，{0}日內有效，客戶可在有效期內預約入園',
    validityTips3: '預訂當天（出發日期）開始，截止到{0}內有效，客戶可在有效期內預約入園',
    validityTips4: '出發日期開始，{0}日內有效，客戶可在有效期內預約入園',
    validityTips5: '出發日期開始，截止到{0}內有效，客戶可在有效期內預約入園',
    changeProductTips:
      '每次只能選擇一個產品的資源，變更產品後，已選中的資源會被清空，確定繼續操作？',
    changeDepartureDateTips:
      '每次只能選擇一個使用日期的資源，變更使用日期後，已選中的資源會被清空，確定繼續操作？',
    changeSeasonTips:
      '每次只能選擇一個場次的資源，變更場次後，已選中的資源會被清空，確定繼續操作？',
    inviteTips: [
      '當前聯絡人不是會員，可',
      '【邀請自主注册】',
      '（發送注册鏈接到聯絡人的郵箱，聯絡人自行注册）或',
      '【代注册】',
      '（在後臺幫聯絡人注册）',
    ],
    notForIndividualSaleTips: '不可單獨銷售',
    productMaxOrderQuantity: '產品最多銷售數量為{0}',
    productMinOrderQuantity: '產品最少銷售數量為{0}',
    resourceMaxOrderQuantity: '資源最多銷售數量為{0}{1}',
    resourceMinOrderQuantity: '資源最少銷售數量為{0}{1}',
    resourceMinWarning: '資源銷售數量需大於等於最小預訂數',
    roomTips: '房型價格為任選一項',
    addRoomTips: '房型價格為任選一項，只能繼續添加已選房型價格',
  },
  inviteRegisterMember: {
    invite: '邀請',
    shareMode: '分享方式',
    pureLink: '純連結',
    sendEmail: '發送電郵',
    linkTips: '連結生成成功，複製連結分享給客人吧！',
    copyLink: '複製連結',
  },

  selectMember: {
    inviteTips: ['如査詢不到會員，可', '【邀請自主注册】', '或', '【代注册】', '代為注册'],
  },
  tour: {
    tip: '請先選擇資源',
    modalTip1: '至少填寫{0}位出遊人，已維護{1}人',
    modalTip2: '，還需維護{0}人',
    modalTip3: '出遊人需填寫:{0}',
    modalTip4: '此出遊人被關聯到資源，不可編輯',
  },
}
