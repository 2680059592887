import i18n from '@/i18n'

// 需要在面包屑展示，但是又不会被在左侧菜单展示的菜单对应关系
export default {
  // 类目列表
  '/category/category-list': [
    {
      path: '/category/category-list/get-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-0'),
      },
    },
    {
      path: '/category/category-list/add-category',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-1'),
      },
    },
    {
      path: '/category/category-list/edit-category',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-2'),
      },
    },
  ],
  // 订单列表
  '/order-sys/order-list': [
    {
      path: '/order-sys/entrusted-ticket',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-148'),
      },
    },
    {
      path: '/order-sys/order-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-3'),
      },
    },
    {
      path: '/order-sys/receive-order',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-4'),
      },
    },
  ],
  // 订单收款列表
  '/order-sys/order-collection': [
    {
      path: '/order-sys/order-collection-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-5'),
      },
    },
    {
      path: '/order-sys/order-collection-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-6'),
      },
    },
    {
      path: '/order-sys/order-collection-info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-7'),
      },
    },
  ],
  '/order-sys/batch-order': [
    {
      path: '/order-sys/batch-order-result',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-8'),
      },
    },
    {
      path: '/order-sys/batch-order-history',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-9'),
      },
    },
  ],
  // 客户对账
  '/order-sys/customer-reconciliation': [
    {
      path: '/order-sys/customer-reconciliation-collection',
      meta: {
        title: i18n.t('收款单详情'), // 收款操作页
      },
    },
    {
      path: '/order-sys/customer-reconciliation-detail',
      meta: {
        title: i18n.t('收款单详情'), // 收款查看页
      },
    },
    {
      path: '/order-sys/customer-supplier-detail',
      meta: {
        title: i18n.t('对账单详情'),
      },
    },
  ],
  // 占位单查询
  '/confirm/occupy': [
    {
      path: '/confirm/occupy/handle',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-10'),
      },
    },
    {
      path: '/confirm/occupy/handle-cancel',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-11'),
      },
    },
    {
      path: '/confirm/occupy/show-cancel',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-12'),
      },
    },
    {
      path: '/confirm/occupy/show',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-13'),
      },
    },
  ],
  // 确认单查询
  '/confirm/confirm': [
    {
      path: '/confirm/confirm/handle',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-14'),
      },
    },
    {
      path: '/confirm/confirm/batchConfirm',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-15'),
      },
    },
    {
      path: '/confirm/confirm/teamConfirm/view',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-16'),
      },
    },
    {
      path: '/confirm/confirm/teamConfirm',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-17'),
      },
    },
    {
      path: '/confirm/confirm/teamConfirm/add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-18'),
      },
    },
    {
      path: '/confirm/confirm/handle-cancel',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-14'),
      },
    },
    {
      path: '/confirm/confirm/show-cancel',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-19'),
      },
    },
    {
      path: '/confirm/confirm/show',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-20'),
      },
    },
  ],
  // 核损单查询
  '/confirm/assloss': [
    {
      path: '/confirm/assloss/handle',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-21'),
      },
    },
    {
      path: '/confirm/assloss/show',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-22'),
      },
    },
  ],
  '/confirm/voucher': [
    {
      path: '/confirm/voucher/handle',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-23'),
      },
    },
    {
      path: '/confirm/voucher/show',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-24'),
      },
    },
  ],

  '/mbs-web/confirm/ticket': [
    {
      path: '/mbs-web/confirm/ticket/detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-25'),
      },
    },
    {
      path: '/mbs-web/confirm/ticket/handle',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-26'),
      },
    },
  ],
  '/mbs-web/confirm/additional': [
    {
      path: '/mbs-web/confirm/additional/detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-27'),
      },
    },
  ],
  // 基础数据-公司列表
  '/basedata-company': [
    {
      path: '/bd-company-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-28'),
      },
    },
  ],
  // 基础数据-门市列表
  '/basedata-store': [
    {
      path: '/bd-store-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-29'),
      },
    },
  ],
  // 用户与组织
  '/user-org': [
    {
      path: '/close-user',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-30'),
      },
    },
  ],
  // 佣金账单
  '/commission-sys/commission-rules': [
    {
      path: '/commission-sys/rule-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-31'),
      },
    },
  ],
  // 佣金账单
  '/commission-sys/commission-bill': [
    {
      path: '/commission-sys/bill-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-32'),
      },
    },
  ],
  // 新增/编辑/查看POI
  '/grounding/poi-list': [
    {
      path: '/grounding/poi-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-33'),
      },
    },
  ],
  // 库存-出/入库单详情
  '/grounding/in-out-library-record': [
    {
      path: '/grounding/godown-entry-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-34'),
      },
    },
    {
      path: '/grounding/outbound-order-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-35'),
      },
    },
  ],
  // 跟团资源列表
  '/grounding/group-resource-list': [
    // 资源详情
    {
      path: '/grounding/resource-info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-36'),
      },
    },
    // 资源编辑页面
    {
      path: '/grounding/group-resource-list/edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-37'),
      },
    },
    // 行程编辑器
    // {
    //   path: '/trip-web/trip/edit',
    //   meta: {
    //     title: i18n.t('layout.noShowMenu.802015-38'),
    //   },
    // },
  ],
  // 资源审核
  '/grounding/resource-audit': [],
  // 常规产品列表
  '/grounding/general-product/list': [
    // 产品新增和编辑
    {
      path: '/grounding/general-product/edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-39'),
      },
    },
    // 产品新增和编辑
    {
      path: '/grounding/product-info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-40'),
      },
    },
  ],
  // 授信管理开始
  '/credit/credit-manage': [
    {
      path: '/credit/credit-manage/detail',
      meta: {
        title: i18n.t('详情'),
      },
    },
  ],
  '/credit/credit-audit': [
    {
      path: '/credit/credit-audit/detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-90'),
      },
    },
  ],
  // 授信管理结束
  // 营销系统开始
  '/mbs-web/marketing-list': [
    {
      path: '/mbs-web/activity-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-91'),
      },
    },
    {
      path: '/mbs-web/add-activity',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-92'),
      },
    },
    {
      path: '/mbs-web/edit-activity',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-93'),
      },
    },
  ],
  // 船票促销活动列表
  '/mbs-web/ferry-activity-list': [
    {
      path: '/mbs-web/add-ferry-activity',
      meta: {
        title: i18n.t('新增船票促销活动'),
      },
    },
    {
      path: '/mbs-web/edit-ferry-activity',
      meta: {
        title: i18n.t('编辑船票促销活动'),
      },
    },
  ],
  // 营销系统结束
  // 小程序运营
  '/applet-operate/maintain-hot-label': [
    {
      path: '/applet-operate/maintain-hot-product',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-94'),
      },
    },
  ],
  '/applet-operate/maintain-pc-product': [
    {
      path: '/applet-operate/maintain-pc-product-list',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-94'),
      },
    },
    {
      path: '/mbs-web/add-maintain-product',
      meta: {
        title: '新增主推產品維護',
      },
    },
    {
      path: '/mbs-web/edit-maintain-product',
      meta: {
        title: '編輯主推產品維護',
      },
    },
  ],
  // 供应商系统
  '/supplier-sys/resource-audit': [
    {
      path: '/supplier-sys/resource/info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-90'),
      },
    },
  ],
  // 公共服务 航班列表
  '/mbs-web/flight-no-list': [
    {
      path: '/mbs-web/add-airlines',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-95'),
      },
    },
    {
      path: '/mbs-web/edit-airlines',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-96'),
      },
    },
  ],
  // 公共服务 合作渠道
  '/mbs-web/channel-list': [
    {
      path: '/mbs-web/edit-compony',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-97'),
      },
    },
  ],
  // 云店店主列表
  '/community-manage/manage-shop-keeper': [
    {
      path: '/community-manage/manage-shop-keeper-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-98'),
      },
    },
    {
      path: '/community-manage/check-manage-shop-keeper',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-99'),
      },
    },
    {
      path: '/community-manage/edit-manage-shop-keeper',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-100'),
      },
    },
  ],
  // 企业员工额度
  '/mbs-web/employee-management': [
    {
      path: '/mbs-web/quota-allocation',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-101'),
      },
    },
    {
      path: '/mbs-web/view-order',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-102'),
      },
    },
  ],
  // 签证系统
  '/mbs-web/visa/manage-visa-list': [
    {
      path: '/mbs-web/visa/manage-visa-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-103'),
      },
    },
  ],
  // 签证材料管理
  '/mbs-web/product/visa-materials-list': [
    {
      path: '/mbs-web/product/visa-materials-edit/visa',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-104'),
      },
    },
    {
      path: '/mbs-web/product/visa-materials-edit/authentication',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-105'),
      },
    },
  ],
  // 签证产品
  '/mbs-web/product/visa-products-list/visa': [
    {
      path: '/mbs-web/product/visa-products-edit/visa',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-106'),
      },
    },
  ],
  // 认证产品
  '/mbs-web/product/visa-products-list/authentication': [
    {
      path: '/mbs-web/product/visa-products-edit/authentication',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-107'),
      },
    },
  ],
  //公告列表
  '/public-sys/announcement-list': [
    {
      path: '/public-sys/announcement-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-108'),
      },
    },
    {
      path: '/public-sys/announcement-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-109'),
      },
    },
  ],
  '/announcements': [
    {
      path: '/announcement-view',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-110'),
      },
    },
  ],
  // 企业合作管理
  '/mbs-web/cooperation-list': [
    {
      path: '/mbs-web/cooperation-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-112'),
      },
    },
  ],
  // 活动产品数据统计表
  '/mbs-web/monitor/pvuv-activity': [
    {
      path: '/mbs-web/monitor/pvuv-activity-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-113'),
      },
    },
  ],
  // 酒店房型价格
  '/mbs-web/stock/house-price': [
    {
      path: '/mbs-web/resources/edit-house-price',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-114'),
      },
    },
  ],
  // 基础数据酒店列表
  '/mbs-web/hotel-list': [
    {
      path: '/mbs-web/room-type-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-115'),
      },
    },
    {
      path: '/mbs-web/room-type-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-116'),
      },
    },
    {
      path: '/mbs-web/room-type-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-117'),
      },
    },
    {
      path: '/mbs-web/hotel-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-118'),
      },
    },
    {
      path: '/mbs-web/hotel-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-119'),
      },
    },
    {
      path: '/mbs-web/hotel-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-120'),
      },
    },
  ],
  // 客商管理
  '/mbs-web/travelling-trader-list': [
    {
      path: '/mbs-web/travelling-trader-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-121'),
      },
    },
    {
      path: '/mbs-web/travelling-trader-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-122'),
      },
    },
    {
      path: '/mbs-web/travelling-trader-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-123'),
      },
    },
  ],
  // 客商审核
  '/mbs-web/travelling-trader-audit-list': [
    {
      path: '/mbs-web/travelling-trader-audit-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-124'),
      },
    },
  ],
  // 产品门券列表
  '/product-sys/ticket-list': [
    {
      path: '/product-sys/ticket-edit',
      meta: {
        title: '門券詳情',
      },
    },
    {
      path: '/product-sys/sales-record-list',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-126'),
      },
    },
    {
      path: '/product-sys/ticket/batch-set-markup-rule',
      meta: {
        title: '批量設置加價規則',
      },
    },
  ],
  // 业财子系统新增--开始
  // 审批待办-start
  '/finance-web/approval-list': [
    {
      path: '/finance-web/approval-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-127'),
      },
    },
  ],
  // 审批待办-end
  // 结算管理-start
  '/finance-web/team-archive-list': [
    {
      path: '/finance-web/team-archive-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.743253-0'),
      },
    },
  ],
  // 结算管理-end
  // 付款管理-----start（业财子系统）
  '/finance-web/payment-apply-list': [
    {
      path: '/finance-web/payment-apply-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-128'),
      },
    },
    {
      path: '/finance-web/refund-doc-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-129'),
      },
    },
  ],
  // 供应商对账
  '/finance-web/bill-compare': [
    {
      path: '/finance-web/bill-compare-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.743253-1'),
      },
    },
  ],
  // 付款单审核
  '/finance-web/payment-review': [
    {
      path: '/finance-web/payment-doc-audit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-130'),
      },
    },
    {
      path: '/finance-web/refund-doc-audit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-131'),
      },
    },
    {
      path: '/finance-web/payment-doc-audit-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.743253-2'),
      },
    },
    {
      path: '/finance-web/refund-doc-audit-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.743253-3'),
      },
    },
  ],
  // 付款管理-----end（业财子系统）
  // 预付管理-start
  '/finance-web/pre-paid-list': [
    {
      path: '/finance-web/pre-paid-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-78'),
      },
      children: [
        {
          path: '/finance-web/recharge-detail',
          meta: {
            title: i18n.t('layout.noShowMenu.802015-68'),
          },
        },
        {
          path: '/finance-web/turn-out',
          meta: {
            title: i18n.t('layout.noShowMenu.802015-132'),
          },
        },
        {
          path: '/finance-web/refund',
          meta: {
            title: i18n.t('layout.noShowMenu.802015-133'),
          },
        },
        {
          path: '/finance-web/refund-detail',
          meta: {
            title: i18n.t('layout.noShowMenu.802015-133'),
          },
        },
      ],
    },
  ],
  '/finance-web/pre-paid-review-list': [
    {
      path: '/finance-web/recharge-review-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-134'),
      },
    },
    {
      path: '/finance-web/refund-review-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-135'),
      },
    },
  ],
  // 预付管理-end
  // 收款start
  '/finance-web/receive-pay/receive': [
    {
      path: '/finance-web/receive-pay/collect-details',
      meta: { title: i18n.t('layout.noShowMenu.802015-46') },
    },
    {
      path: '/finance-web/receive-pay/receive/collect-summary-details',
      meta: { title: i18n.t('layout.noShowMenu.802015-47') },
    },
  ],
  '/finance-web/receive-pay/pre-receipt-query': [
    // 预收款查询
    {
      path: '/finance-web/receive-pay/pre-receipt-create',
      meta: { title: i18n.t('layout.noShowMenu.743253-4') },
    },
    {
      path: '/finance-web/receive-pay/pre-receipt-detail',
      meta: { title: i18n.t('layout.noShowMenu.743253-5') },
    },
    {
      path: '/finance-web/receive-pay/pre-receipt-edit',
      meta: { title: i18n.t('layout.noShowMenu.743253-6') },
    },
    {
      path: '/finance-web/receive-pay/pre-receipt-turn-out',
      meta: { title: i18n.t('layout.noShowMenu.743253-7') },
    },
    {
      path: '/finance-web/receive-pay/refund-apply',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-147'),
      },
    },
  ],
  '/finance-web/receive-pay/refund-query': [
    {
      path: '/finance-web/receive-pay/refund-details',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-129'),
      },
    },
  ],
  '/finance-web/receive-pay/collect-refund-review': [
    {
      path: '/finance-web/receive-pay/refund-check',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-144'),
      },
    },
    {
      path: '/finance-web/receive-pay/collect-summary-details',
      meta: { title: i18n.t('layout.noShowMenu.802015-473') },
    },
  ],
  // 收款end
  // 业财子系统新增--结束
  // 工作流列表
  '/mbs-web/public-sys/workflow-list': [
    {
      path: '/mbs-web/public-sys/workflow-info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-138'),
      },
      children: [
        {
          path: '/mbs-web/public-sys/approval-rules',
          meta: {
            title: i18n.t('layout.noShowMenu.802015-111'),
          },
        },
      ],
    },
  ],
  // 公共服务审批列表
  '/mbs-web/public-sys/approval-list': [
    {
      path: '/mbs-web/public-sys/approval-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-139'),
      },
    },
  ],
  // 产品审批列表
  '/mbs-web/product/approval-list': [
    {
      path: '/mbs-web/product/approval-info',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-139'),
      },
    },
  ],
  // 会员列表
  '/mbs-web/member-list': [
    {
      path: '/mbs-web/member-list',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-140'),
      },
    },
    {
      path: '/mbs-web/member-detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-141'),
      },
    },
  ],
  // 会员积分列表
  '/mbs-web/member/points-change-apply-list': [
    {
      path: '/mbs-web/member/points-change-apply',
      meta: {
        title: i18n.t('添加积分变动申请'),
      },
    },
    {
      path: '/mbs-web/member/points-change-apply-detail',
      meta: {
        title: i18n.t('积分变动申请详情'),
      },
    },
  ],
  // 资讯列表
  '/mbs-web/information-list': [
    {
      path: '/mbs-web/information-list-add',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-142'),
      },
    },
    {
      path: '/mbs-web/information-list-edit',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-143'),
      },
    },
  ],
  '/salesReport/list': [
    {
      path: '/salesReport/detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-145'),
      },
    },
  ],
  '/salesReport/approveList': [
    {
      path: '/salesReport/approve',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-145'),
      },
    },
    {
      path: '/salesReport/approve/detail',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-145'),
      },
    },
  ],
  '/mbs-web/activity-manage-list': [
    {
      path: '/mbs-web/add-activity-manage',
      meta: {
        title: i18n.t('layout.noShowMenu.802015-146'),
      },
    },
    {
      path: '/applet-operate/activity-product-list',
      meta: {
        title: '產品維護',
      },
    },
  ],
  '/mbs-web/product/hotel-list': [
    {
      path: '/mbs-web/product/hotel-edit',
      meta: {
        title: '酒店產品編輯',
      },
    },
    {
      path: '/mbs-web/product/hotel-info',
      meta: {
        title: '酒店產品詳情',
      },
    },
    {
      path: '/product-sys/hotel/batch-set-markup-rule',
      meta: {
        title: '批量設置加價規則',
      },
    },
  ],
  // 库存看板
  '/grounding/inventory-kanban': [
    {
      path: '/mbs-web/stock/reserved-inventory',
      meta: {
        title: '預留庫存',
      },
    },
  ],
  // 酒店套餐产品
  '/product-sys/hotel-set/list': [
    {
      path: '/product-sys/hotel-set/edit',
      meta: {
        title: '酒店套餐编辑',
      },
    },
    {
      path: '/product-sys/hotel-set/info',
      meta: {
        title: '酒店套餐详情',
      },
    },
    {
      path: '/product-sys/hotel-set/batch-set-markup-rule',
      meta: {
        title: '批量設置加價規則',
      },
    },
  ],
  // 印花管理
  '/finance-web/printing-management': [
    {
      path: '/finance-web/pay-dtamp-duty-info',
      meta: {
        title: '繳印花稅詳情',
      },
    },
    {
      path: '/finance-web/printing-management-info',
      meta: {
        title: '印花管理详情',
      },
    },
  ],
  // 確認管理 /酒店報房
  '/mbs-web/confirm/hotel-room-reservation': [
    {
      path: '/mbs-web/confirm/hotel-room-reservation/edit',
      meta: {
        title: '酒店報房編輯',
      },
    },
    {
      path: '/mbs-web/confirm/hotel-room-reservation/detail',
      meta: {
        title: '酒店報房詳情',
      },
    },
  ],
}
